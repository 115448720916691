$(document).ready(function(){

  $(".menu-button").click(function(){
    $(this).toggleClass("is-active")
    $('.navigation').toggleClass("active")
  })
  $('.nav-link').on('click', function(){
    if($(this).next().hasClass('sub-nav')){
      $(this).next().toggleClass('hide')
      $(this).children('.icon-nav-link').toggleClass('active')
    }
  })

  if($('.form-date').length > 0){
  	$('.form-date').pickadate({
      min: 1,
      disable: disableDates,
      container: 'body'
    });
    $('.form-date').on('mousedown',function(event){
      event.preventDefault();
    })
  }


  if($('#aniimated-thumbnials').length > 0){
    $('#aniimated-thumbnials').lightGallery({
      thumbnail: false,
      selector: '.img-wrapper'
    })
  }

  function openGallery(event, index) {
    event.preventDefault()
    console.log(index)
    $(this).lightGallery({
        dynamic: true,
        dynamicEl: [{
            "src": $(this).find('.micro-radio-image img').attr('src')
        }],
        download: false,
        fullscreen: false,
        share: false,
        thumbnail: false
    })
  }
  $('.micro-radio').on('dblclick', openGallery)
  $('.micro-radio').on('contextmenu', openGallery)
  if($('#autoWidth').length > 0){
    $('#autoWidth').slick({
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      centerMode: false,
      centerPadding: '20%',
      variableWidth: true,
      rows: 0,
    })
  }
  if($('.checkout-title').length > 0){
    $('.checkout-title').on('click', function(){
      $('.checkout-wrapper').toggleClass('active')
      hasCheckoutBeenOpened = true
    })
  }
  // Events with Contact Form 7
  var $successBox = $('.success-message')

  document.addEventListener('wpcf7mailsent', function(e) {
    $successBox.toggleClass('active')
  }, false)

  $successBox.find('.close').on('click', function(e) {
    $successBox.removeClass('active')
  })

  // Services
  var $services = $('input[name="service"]')
  var $cf7services = $('#your-service')

  $services.on('change', function(e) {
    var name = $(this).val()
    $cf7services.val(name)
  })




  $('.form-input').on('focus', function(e) {
    e.stopPropagation();
    if (!$(this).hasClass('form-date')) {
      let $input = $(this);
      let $wrapper = $input.closest('.form-label-wrapper');
      $wrapper.find('.form-label').addClass('focused');
    }
  })

  /*$('.form-input').on('focusout', function(e) {
    let $input = $(this)
    let $wrapper = $input.closest('.form-label-wrapper')
    if (!$input.valid()) {
      $wrapper.find('.form-label').removeClass('focused')
    }
  })*/


})
function mobileOnlySlider(selector) {
  $(selector).slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    centerMode: true,
    centerPadding: '10%',
    variableWidth: true,
    rows: 0,
    touchThreshold:100
  })
}
$(window).on('load resize orientationchange', function() {
  if(window.innerWidth < 768) {
    if(!$('#form-services').hasClass('slick-initialized')){
      mobileOnlySlider('#form-services');
    }
    if(!$('#list-services').hasClass('slick-initialized')){
      mobileOnlySlider('#list-services');
    }
  }else{
    if($('#form-services').hasClass('slick-initialized')){
      $('#form-services').slick('unslick');
    }
    if($('#list-services').hasClass('slick-initialized')){
      $('#list-services').slick('unslick');
    }
  }
})

document.addEventListener('DOMContentLoaded', function () {
  const sliders = document.querySelectorAll('.testimonial-slider.w-slider'); // Selecciona todos los sliders en la página

  sliders.forEach(function (slider) {
    const slides = slider.querySelectorAll('.w-slide'); // Selecciona todas las slides
    const dots = slider.querySelectorAll('.w-slider-dot'); // Selecciona todos los dots
    let activeIndex = 0; // Índice inicial de la diapositiva activa

    // Función para actualizar la clase de la diapositiva activa
    function updateActiveSlide(index) {
      slides.forEach((slide, i) => {
        slide.classList.toggle('testimonial-active', i === index); // Aplica o remueve la clase
      });
    }

    // Listener para cada dot (punto de navegación)
    dots.forEach((dot, index) => {
      dot.addEventListener('click', function () {
        activeIndex = index; // Actualiza el índice con el dot clicado
        updateActiveSlide(activeIndex); // Actualiza la diapositiva activa
      });
    });

    // Inicializa la primera diapositiva como activa
    updateActiveSlide(activeIndex);
  });
});
